import { Component, HostListener, Input, OnDestroy, ViewChild, ElementRef, OnInit, Injector, Inject } from '@angular/core';
import * as core from '@egr/wcf/modules/core';
import { wcfConfig } from "@egr/wcf/modules/utils";
import { BasketItem, InsertInfo, PasteOptions } from '@egr/wcf/modules/eaiws/basket';
import { CustomArticleManager } from 'src/app/models/eaiws/CustomArticleManager';
import { ViewerService } from 'src/app/services/viewer.service';
import { EaiwsService } from 'src/app/services/eaiws.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { BasketFolderService } from 'src/app/services/basket-folder.service';
import { environment } from 'src/environments/environment';
import { EaiwsOptions } from 'src/app/models/options';
import { LanguageService } from 'src/app/services/language.service';


export abstract class EaiwsBaseComponent {

    options: EaiwsOptions = {
        orderNumber: "",
        showCatalog: false,
        showPrice: false,
        showPriceOptions: false,
        canArticleDelete: false,
        canConfigured: false,
        catalogPreSelect: "",
        showCallToAcion: false,
        productPreSelect: "",
        restrictConfiguration: false,
        allowedConfiguration: [],
        disabledSeries: [],
        showBreadcrump: true,
        backend: ""
    }

    sessionID: string = ""
    items: BasketItem[] = []
    isMobile = false
    isMobile700 = false
    finishedLoading = false
    clickedItemID = ""

    protected newArticleAdded
    protected itemClickedEvent
    protected reloadBasketItemsEvent
    protected viewerUI: ElementRef<HTMLDivElement> | undefined

    readonly BASEURL = "https://configurator.sedus.com"
    readonly SESSION_COOKIE = "w3code_eaiws_sessionKey"

    public eaiwsService: EaiwsService
    public catalogService: CatalogService
    public basketFolderService: BasketFolderService
    public viewerService: ViewerService
    public languageService: LanguageService

    private lastLoadedOBX = ""


    constructor(@Inject(Injector) injector: Injector) {
        this.eaiwsService = injector.get(EaiwsService)
        this.catalogService = injector.get(CatalogService)
        this.basketFolderService = injector.get(BasketFolderService)
        this.viewerService = injector.get(ViewerService)
        this.languageService = injector.get(LanguageService)



        //setup w-cf
        wcfConfig.libsPath = window.location.origin + "/assets/wcf/libs/";
        wcfConfig.dataPath = window.location.origin + "/assets/wcf/data/";

        this.newArticleAdded = this.catalogService.newArticle.subscribe((item) => {
            void this.loadArticle(item.baseArticleNumber, undefined, item.catalogPackageId).then(() => {
                this.basketFolderService.itemClickedEmitter.next(this.items[this.items.length - 1].itemId)
            })
        })

        this.itemClickedEvent = this.basketFolderService.itemClickedEmitter.subscribe((clickedItemID) => {
            this.clickedItemID = clickedItemID
            setTimeout(() => {
                this.scrollToViewer()
            }, 100)
        })

        this.reloadBasketItemsEvent = this.eaiwsService.realodBasketItems.subscribe(() => {
            this.eaiwsService.eaiwsSession.basket.getAllItems().then(items => {
                this.items = items
            })
        })

        this.checkWindowWidth(window.innerWidth)
    }


    async init() {
        this.sessionID = localStorage[this.getJsStorageKey()];
        await this.connectSession()


        const ppList = await this.eaiwsService.eaiwsSession.basket.listPricingProcedures(true)
        if (!ppList.find(ppEntry => ppEntry.name == "STDB2B_SEDUS_2")) {
            await this.eaiwsService.eaiwsSession.basket.addPriceCalculation("STDB2B_SEDUS_2")
        }


        this.eaiwsService.coreApp.dispose()
        const appOptions: Partial<core.AppInitOptions> = {};
        this.eaiwsService.coreApp.initialize(document.getElementById('viewer') as HTMLDivElement, appOptions);
        this.viewerService.init(this.eaiwsService.coreApp)
        this.eaiwsService.articleManager = new CustomArticleManager(this.eaiwsService.coreApp, this.eaiwsService.eaiwsSession)
        void this.eaiwsService.articleManager.load()

        this.items = await this.eaiwsService.eaiwsSession.basket.getAllItems()
        this.languageService.updateEaiwsLanguage()

        if (this.options.orderNumber && this.options.orderNumber != "") {
            this.eaiwsService.coreApp.document.clear();
            await this.loadOrder(this.options.orderNumber).catch(() => {
                console.log("OBX ist nicht vorhanden")
                alert("Auftrag konnte nicht geladen werden")
            })
        }

        await Promise.all([
            this.checkPreSelectProduct(),
            this.checkPreSelectOBX()
        ])
        await this.filterAllowedSerie()

        this.finishedLoading = true
        this.eaiwsService.eventFinishedLoadEAIWS.next("")
    }

    private async filterAllowedSerie() {
        if (!this.options.allowedSerie || this.options.allowedSerie.length == 0) return

        //Serie auslesen
        const props = await this.eaiwsService.eaiwsSession.basket.getItemProperties(this.items.map(item => item.itemId))
        const filteredProps = props.filter((prop, index) => {
            if (index == 0) return true
            if (prop.itemType == "Folder") return true
            if (!prop.article || !prop.article.seriesId) return false
            if (!this.options.allowedSerie) return false

            return this.options.allowedSerie.indexOf(prop.article.seriesId) >= 0
        })

        //Artikel mit der passenden Serie kopieren -> alle löschen -> kopiertes einfüegn
        const url = await this.eaiwsService.eaiwsSession.basket.copy(filteredProps.map(item => item.itemId ? item.itemId : ""))
        await this.eaiwsService.eaiwsSession.basket.deleteItems(this.items.map(item => item.itemId))
        await this.eaiwsService.eaiwsSession.basket.paste(this.items[0].itemId, null, url)
        await this.eaiwsService.eaiwsSession.deleteFile(url);


        this.items = await this.eaiwsService.eaiwsSession.basket.getAllItems()
    }

    private async checkPreSelectProduct() {
        if (!this.options.productPreSelect || this.options.productPreSelect == "") return
        if (!this.options.manufacturer) return

        //Prüfen ob vorselketiertes Produt schon im Basket ist
        const props = await this.eaiwsService.eaiwsSession.basket.getItemProperties(this.items.filter(item => item.itemType == "Aggregate").map(item => item.itemId))
        const preProduct = props.find(prop => {
            if (!prop.article) return false

            return prop.article.baseArticleNumber == this.options.productPreSelect
        })

        if (preProduct) {
            // vorhandener Artikel anzeigen
            if (!preProduct.itemId) return

            this.basketFolderService.itemClickedEmitter.next(preProduct.itemId)
        } else {
            // Artikel hinzufügen
            await this.loadArticle(this.options.productPreSelect, this.options.manufacturer.manufacturer)
            this.basketFolderService.itemClickedEmitter.next(this.items[this.items.length - 1].itemId)
        }
    }

    private async checkPreSelectOBX() {
        if (!this.options.obxPreSelect || this.options.obxPreSelect == "") return

        await this.loadOBKIntoSession("assets/OBK/manuel/" + this.options.obxPreSelect + ".obx")
    }

    private async loadOrder(orderNumber: string) {
        if (!this.eaiwsService.coreApp) return Promise.reject()
        if (!this.eaiwsService.eaiwsSession) return Promise.reject()
        if (!this.eaiwsService.articleManager) return Promise.reject()

        //Alle Dateien zu dem Auftrag laden
        const filesResult = await fetch(this.options.backend + "/obxfiles/" + environment.sapSystem + "/" + orderNumber, {
            method: "GET"
        })

        const files: string[] = await filesResult.json()
        let countLoadedFiles = 0

        if (files.length == 0) return Promise.reject()

        this.eaiwsService.eaiwsSession.basket.deleteItems(this.items.map(item => item.itemId))

        //Alle Dateien in den Basket laden
        for (let i = 0; i < files.length; i++) {
            const fileName = files[i];
            if (! await this.loadOBKIntoSession("assets/OBK/" + environment.sapSystem + "/" + fileName)) continue

            countLoadedFiles++
        }

        if (countLoadedFiles == 0) return Promise.reject()

        //Wenn etwas geladen wurde, dann die Selection anpassen
        if (this.eaiwsService.coreApp.model.elements.length > 0) {
            this.eaiwsService.coreApp.model.setSelection([this.eaiwsService.coreApp.model.elements[0]]);
        }

        return Promise.resolve()
    }

    private async loadOBKIntoSession(path: string) {
        const fileData = await this.eaiwsService.getBlob(path).catch(() => {
            console.log("Datei konnte nicht geladen werden")
        })

        if (!fileData) return false

        let obxData = await fileData.text()
        obxData = obxData.replace("<![CDATA[", "")
        obxData = obxData.replace("]]>", "")

        if (this.lastLoadedOBX.substring(0, 1000) == obxData.substring(0, 1000)) return true

        this.lastLoadedOBX = obxData
        let fileData2 = new Blob([obxData])

        const fatherID = this.items[0].itemId
        const url = await this.eaiwsService.eaiwsSession.session.getUploadURL("CutBuffer")
        await this.eaiwsService.eaiwsSession.uploadFileToUrl(url, fileData2)
        const pastedItemIDs = await this.eaiwsService.eaiwsSession.basket.paste(fatherID, null, url)
        await this.eaiwsService.eaiwsSession.deleteFile(url);

        pastedItemIDs.unshift(fatherID)
        this.items = await this.eaiwsService.eaiwsSession.basket.getAllItems(pastedItemIDs)

        return true
    }


    private async loadArticle(baseArticleNumber: string, manufacturerId?: string, catalogPackageId?: string) {
        if (!this.eaiwsService.articleManager) return Promise.reject()
        if (!this.eaiwsService.eaiwsSession) return Promise.reject()

        const insertOption = new InsertInfo()
        insertOption.baseArticleNumber = baseArticleNumber
        insertOption.manufacturerId = manufacturerId
        insertOption.catalogPackageId = catalogPackageId

        await this.eaiwsService.articleManager.addNewArticle(insertOption)
        this.viewerService.resetCamera()

        this.items = await this.eaiwsService.eaiwsSession.basket.getAllItems()

        return Promise.resolve()
    }



    private async connectSession() {
        if (!this.eaiwsService.eaiwsSession) return

        if (true != true) {
            await this.openSession();
            console.log("new Session")
            return
        }

        if (!this.eaiwsService.eaiwsSession.connect(this.BASEURL, this.sessionID)) {
            console.log("new Session");
            await this.openSession();
        } else {
            if (!await this.eaiwsService.eaiwsSession.session.keepAlive()) {
                await this.openSession();
                console.log("new Session");
            } else {
                console.log("open Session");
            }
        }
    }


    private openSession() {
        return new Promise<string>(async (resolve, reject) => {
            if (!this.eaiwsService.eaiwsSession) return reject("Es ist kein EAIWS Session Objekt erstellt worden")
            if (!this.options.manufacturer) return reject("In den Optionen ist kein Hersteller angegeben")

            await this.eaiwsService.eaiwsSession.open(this.BASEURL, { startup: this.options.manufacturer.startUp })
            localStorage[this.getJsStorageKey()] = this.eaiwsService.eaiwsSession.sessionId

            resolve(this.eaiwsService.eaiwsSession.sessionId)
        })
    }

    getJsStorageKey() {
        return this.SESSION_COOKIE + (this.options.orderNumber ? this.options.orderNumber : "")
    }


    onDragEnd() {
        const appOptions: Partial<core.AppInitOptions> = {};
        this.eaiwsService.coreApp.viewer.resize()
    }





    protected checkWindowWidth(width: number) {
        this.isMobile = width <= 900
        this.isMobile700 = width <= 700
    }


    protected scrollToViewer() {
        const viewer = document.getElementById('viewer')
        if (!viewer) return

        const rect = viewer.getBoundingClientRect();
        window.scrollTo({
            top: rect.y + window.scrollY,
            behavior: 'smooth'
        });
    }
}




export interface ConfigurationConfig {
    baseAticleNumber: string[]
    settings: ConfigurationConfigArticleSetting[]
}
export interface ConfigurationConfigArticleSetting {
    key: string,
    values: string[]
}
