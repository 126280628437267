import * as core from '@egr/wcf/modules/core';
import * as eaiws from '@egr/wcf/modules/eaiws';
import { Injectable } from '@angular/core';
import { CustomArticleManager } from '../models/eaiws/CustomArticleManager';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class EaiwsService {
    public coreApp: core.Application
    public eaiwsSession: eaiws.EaiwsSession
    public articleManager: CustomArticleManager | undefined

    eventFinishedLoadEAIWS = new Subject()
    realodBasketItems = new Subject()

    constructor() {
        this.eaiwsSession = new eaiws.EaiwsSession()

        this.coreApp = new core.Application()
        this.coreApp.applicationName = "w3code-online-ab-viewer"
        this.coreApp.applicationVersion = "1.0.0";


    }


    getBlob(url: string) {
        return new Promise<Blob>((resolve, reject) => {
            const xhr = new XMLHttpRequest()
            xhr.open("GET", url, true)
            xhr.responseType = "blob"
            xhr.onload = function () {
                if (this.status == 200) {
                    resolve(this.response)
                }

                if (this.status == 404) {
                    reject(this.response)
                }
            }
            xhr.onerror = () => {
                reject()
            }
            xhr.send()
        })
    }

    isSematrixPlanningArticle(article: string) {
        return article == 'SY20MD-MODUL' || article == 'SY20MD-ADDON' || article == 'SY20MD-LIGHT' || article == 'SY20MD-MONMO'
    }
}
