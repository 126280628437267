import { Component, Injector, Input } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AddressData, CommAddress, ContactData } from '@egr/wcf/modules/eaiws/project';
import { EaiwsOptions } from 'src/app/models/options';
import { PageW3codeBase } from 'src/app/pages/page-w3code-base';
import { EaiwsService } from 'src/app/services/eaiws.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-form-kloeber',
    templateUrl: './form-kloeber.component.html',
    styleUrls: ['./form-kloeber.component.scss']
})
export class FormKloeberComponent extends PageW3codeBase {
    message = ""
    contactData = {
        firstname: "",
        lastname: "",
        company: "",
        city: "",
        plz: "",
        street: "",
        phone: "",
        comment: "",
        checkbox: false,
        requestImages: false,
        requestImagesType: "Keines",
        newsletter: false,
        privacy: false,
        targetgroup: "Interesse_Endkunde",
        mail: ""
    }
    mailStatus: "open" | "sending" | "finish" = "open"
    imagesCheckedTypes: { type: string, checked: boolean }[] = []

    @Input() options: EaiwsOptions = {
        canBuy: false,
        backend: ""
    }

    constructor(private injector: Injector, private eaiwsService: EaiwsService) {
        super(injector)
    }

    async onClickBuy() {
        this.prepareMail('Buy')
    }

    async onClickRequestOffer() {
        this.prepareMail('Offer')
    }

    async prepareMail(type: "Offer" | "Buy") {
        if (!this.validateForm()) {
            this.handleMessage("error.validateForm")
            return
        }

        this.mailStatus = "sending"

        await this.transferContactToEAIWS()

        //OBK laden
        const obkUrl = await this.eaiwsService.eaiwsSession.session.saveSession(null)
        const obkData = await this.toDataUrl(obkUrl)

        //PDF zur aktuellen Angebot generieren und URL auslesen
        await fetch(this.eaiwsService.eaiwsSession.baseUrl + "/EAIWS/plugins/Reporter/template/pCon_basket_Sedus_Kloeber/parameters", {
            method: "POST",
            body: JSON.stringify({
                "sessionId": this.eaiwsService.eaiwsSession.session.sessionId,
                "parameters": {
                    "egr_ui_CompanyLogo_ShowCompanyLogo": true,
                    "egr_ui_CompanyLogo_LogoPath": null,
                    "egr_ui_Header_Letterhead": true,
                    "egr_ui_Header_ShippingAddress": true,
                    "egr_ui_Header_ProjectNumber": true,
                    "egr_ui_Header_ProjectName": true,
                    "egr_ui_Header_HeaderText": true,
                    "egr_ui_Header_ExtRefNo": true,
                    "egr_ui_Header_obligation": false,
                    "egr_ui_Header_AddressAlignment": "Left",
                    "egr_ui_Header_Title": "",
                    "egr_ui_Header_FirstPageNumber": "1",
                    "egr_ui_ArticleList_FrontPage": false,
                    "egr_ui_ArticleList_ItemNumber": "Default",
                    "egr_ui_ArticleList_ShortText": true,
                    "egr_ui_ArticleList_LongText": true,
                    "egr_ui_ArticleList_VariantText": true,
                    "egr_ui_ArticleList_SpecialModelInformation": true,
                    "egr_ui_ArticleList_AdditionalTexts": true,
                    "egr_ui_ArticleList_HidePrices": !this.options.showPrice,
                    "egr_ui_ArticleList_PrintConditionTypes": true,
                    "egr_ui_ArticleList_ShowAdditionalPricesPosition": true,
                    "egr_ui_ArticleList_PriceAdjustmentValueSurcharge": true,
                    "egr_ui_ArticleList_PriceAdjustmentValueDiscount": true,
                    "egr_ui_ArticleList_PriceAdjustmentPercentSurcharge": true,
                    "egr_ui_ArticleList_PriceAdjustmentPercentDiscount": true,
                    "egr_ui_ArticleList_ShowGrossPrice": true,
                    "egr_ui_ArticleList_ShowPositionDiscounts": true,
                    "egr_ui_ArticleList_ShowPositionDiscounts%": true,
                    "egr_ui_ArticleList_ShowPositionNetPrice": true,
                    "egr_ui_ArticleList_ShowPositionNetPriceBold": true,
                    "egr_ui_ArticleList_ShowNetValueUpcharges": true,
                    "egr_ui_ArticleList_AlternativeGrey": true,
                    "egr_ui_ArticleList_DisplaySubPositionOfSetItem": true,
                    "egr_ui_ArticleList_SubTotals": true,
                    "egr_ui_ArticleList_ShowSetArticlePrices": true,
                    "egr_ui_ArticleList_KeepArticleTogether": true,
                    "egr_ui_ArticleList_FolderImageSize": "standard",
                    "egr_ui_ArticleList_ArticleImageSize": "medium",
                    "egr_ui_ArticleList_AdditionalImagesSize": "medium",
                    "egr_ui_FootOfOffer_NetTotal": true,
                    "egr_ui_FootOfOffer_EndText": true,
                    "egr_ui_FootOfOffer_SummeryOnLastPage": false,
                    "egr_ui_FootOfOffer_Total": true,
                    "egr_ui_FootOfOffer_Discounts": true,
                    "egr_ui_FootOfOffer_Tax": true,
                    "egr_ui_Footer_DisplayFooter": true,
                    "egr_ui_Footer_image": null,
                    "egr_ui_Footer_FooterText": null
                },
                "pagebreaks": {}
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        })



        const pdfUrl = await fetch(this.eaiwsService.eaiwsSession.baseUrl + "/EAIWS/plugins/Reporter/template/pCon_basket_Sedus_Kloeber/generate", {
            method: "POST",
            body: JSON.stringify({
                "sessionId": this.eaiwsService.eaiwsSession.session.sessionId,
                "calculationScheme": "STDB2B_SEDUS_2",
                "preferredImageColumn": "cc080d73-88f4-4bfc-8ec1-e7e2ad30739a",
                "externalRefColumn": "c962203c-7e83-4a2f-8060-acaa4a06c921"
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).catch(error => {
            console.log("Fehler PDF Speichern")
            this.mailStatus = "finish"
            return undefined
        })

        if (!pdfUrl) {
            this.handleMessage("error.sendOffer")
            this.mailStatus = "finish"
            return
        }

        const pdfData = await this.toDataUrl(await pdfUrl.text())
        let to = "kontakt@w3code.de;info@kloeber.com"

        if (this.contactData.requestImages) {
            to += ";marketing@kloeber.com"
        }

        //Mail versenden
        const settings: mail = {
            to: to,
            subject: this.translateService.instant("mail.klx.subject" + type),
            body: `<table>
                <tr>
                    <td>${this.translateService.instant("label.firstname")}:</td>
                    <td>${this.contactData.firstname}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.lastname")}:</td>
                    <td>${this.contactData.lastname}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.company")}:</td>
                    <td>${this.contactData.company}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.city")}:</td>
                    <td>${this.contactData.city}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.plz")}:</td>
                    <td>${this.contactData.plz}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.street")}:</td>
                    <td>${this.contactData.street}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.phone")}:</td>
                    <td>${this.contactData.phone}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.mail")}:</td>
                    <td>${this.contactData.mail}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.comment")}:</td>
                    <td>${this.contactData.comment}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.forwardRequest")}:</td>
                    <td>${this.contactData.checkbox ? "Ja" : "Nein"}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.imageType")}:</td>
                    <td>${this.contactData.requestImagesType}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.newsletter")}:</td>
                    <td>${this.contactData.targetgroup}</td>
                </tr>
            </table>`,
            attachmets: [
                { data: obkData.split(",")[1], name: "anfrage.obk" },
                { data: pdfData.split(",")[1], name: "anfrage.pdf" }
            ]
        }

        try {
            const settings2 = JSON.parse(JSON.stringify(settings))
            settings.to = this.contactData.mail

            let results = await Promise.all([this.sendMail(settings2), this.sendMail(settings), this.sendNewsletter()])
            const error = results.find(result => result < 200 || result > 299)

            if (error) {
                this.handleMessage("error.sendOffer")
            } else {
                this.handleMessage("success.sendOffer")
            }
        } catch (error) {
            console.log(error)
            this.handleMessage("error.sendOffer")
        }
    }

    handleMessage(messageKey: string) {
        this.message = this.translateService.instant(messageKey)
        this.mailStatus = "finish"
    }

    async sendMail(settings: mail) {
        const result = await fetch(this.options.backend + "/klx/mail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(settings),
        }).catch(error => {
            return { status: 500 }
        })
        return result.status
    }

    async sendNewsletter() {
        if (!this.contactData.newsletter) return 200

        const result = await fetch(this.options.backend + "/klx/newsletter", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "mail": this.contactData.mail,
                "firstname": this.contactData.firstname,
                "lastname": this.contactData.lastname,
                "company": this.contactData.company,
                "city": this.contactData.city,
                "zip": this.contactData.plz,
                "street": this.contactData.street,
                "targetgroup": this.contactData.targetgroup,
                "doikey": this.translateService.instant("mail.klx.doikey"),
                "language": this.translateService.currentLang
            }),
        }).catch(error => {
            return { status: 500 }
        })
        return result.status
    }


    async transferContactToEAIWS() {
        //Kontaktdaten in die OBk speichern
        const commAdressMail = new CommAddress()
        commAdressMail.scope = "Business"
        commAdressMail.type = "EMail"
        commAdressMail.value = this.contactData.mail

        const commAdressPhone = new CommAddress()
        commAdressPhone.scope = "Business"
        commAdressPhone.type = "Phone"
        commAdressPhone.value = this.contactData.phone

        const contact = new ContactData()
        contact.contactType = "Client"
        contact.firstName = this.contactData.firstname
        contact.lastName = this.contactData.lastname
        contact.commAddresses = [commAdressPhone, commAdressMail]

        const address = new AddressData()
        address.addressType = "SoldTo"
        address.name1 = this.contactData.company
        address.street = this.contactData.street
        address.postalCode = this.contactData.plz
        address.contacts = [contact]
        await this.eaiwsService.eaiwsSession.project.setAddressData(address)
    }

    validateForm() {
        if (!this.contactData.privacy) return false
        if (this.contactData.city == "") return false
        if (this.contactData.firstname == "") return false
        if (this.contactData.lastname == "") return false
        if (this.contactData.mail == "") return false
        if (this.contactData.plz == "") return false
        if (this.contactData.street == "") return false

        return true
    }


    toDataUrl(url: string) {
        return new Promise<string>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                const reader = new FileReader();
                reader.onloadend = function () {
                    resolve(typeof reader.result == 'string' ? reader.result : "");
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        })
    }

    onClickGoBack() {
        this.formService.showHideForm.next(false)
    }

    onCheckBoxChange(arg: MatCheckboxChange) {
        this.contactData.checkbox = arg.checked
    }

    onChangeImageType(arg: MatCheckboxChange, type: string) {
        let entry = this.imagesCheckedTypes.find(img => img.type == type)

        if (!entry) {
            entry = {
                checked: arg.checked,
                type: type
            }

            this.imagesCheckedTypes.push(entry)
        } else {
            entry.checked = arg.checked
        }

        this.contactData.requestImagesType = this.imagesCheckedTypes.filter(img => img.checked).map(img => img.type).join(", ")
    }
}

export interface mail {
    to: string
    attachmets: {
        data: string
        name: string
    }[]
    subject: string
    body: string
}
