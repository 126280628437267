import { Component, Injector } from '@angular/core';
import { ParasConfigurator } from 'src/app/app-routing.module';
import { BasketFolderService } from 'src/app/services/basket-folder.service';
import { PageW3codeBase } from '../page-w3code-base';
import { EaiwsOptions } from 'src/app/models/options';
import { environment } from 'src/environments/environment';
import { Manufacturer } from 'src/app/models/manufacturer';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-page-kloeber-configurator',
    templateUrl: './page-kloeber-configurator.component.html',
    styleUrls: ['./page-kloeber-configurator.component.scss']
})
export class PageKloeberConfiguratorComponent extends PageW3codeBase {
    showFormular = false
    callToActionEvent
    showHideFormEvent
    fixHeader = false

    options: EaiwsOptions = {
        showPrice: false,
        showPriceOptions: true,
        showDiscountPrice: false,
        showCallToAcion: true,
        showZoomIcons: true,
        canConfigured: true,
        canArticleDelete: true,
        showCatalog: true,
        showConfiguration: true,
        disabledSeries: [],
        productPreSelect: "",
        backend: environment.backendBaseUrlKloeber,
        manufacturer: Manufacturer.kloeber,
        designClass: "kloeber",
        showBreadcrump: false,
        catalogPreSelect: ""
    }

    constructor(private injector: Injector, private basketService: BasketFolderService, private titleService: Title, private translate: TranslateService) {
        super(injector)

        translate.get("title.title_kl").subscribe(text => {
            this.titleService.setTitle(text);
        })

        const favIcon = <HTMLLinkElement>document.querySelector('#favicon');
        if (favIcon) {
            favIcon.href = 'favicon_kl.ico';
        }



        this.options.disabledSeries = ["kre", "akt"]

        this.activatedRoute.params.subscribe(paras => {
            const parameter = <ParasConfigurator>paras

            this.options.catalogPreSelect = parameter.productFamily
            this.options.productPreSelect = parameter.product
        })

        this.callToActionEvent = this.basketService.callToActionEmitter.subscribe(() => {
            this.showFormular = true
        })

        this.showHideFormEvent = this.formService.showHideForm.subscribe(show => {
            this.showFormular = show
        })

        window.addEventListener('scroll', (e) => {
            this.fixHeader = window.scrollY > 100
        }, true);
    }
}
