import { Injectable, OnDestroy } from '@angular/core';
import { Application } from '@egr/wcf/modules/core';
import { SelectionDefault } from '@egr/wcf/modules/core/tool';
import { ShadowMapping, ShadowPlane } from '@egr/wcf/modules/core/rendering';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { CreateTube } from '@babylonjs/core/Meshes/Builders';
import { Mesh, Color3, StandardMaterial, Matrix } from '@babylonjs/core';
import { CameraControl } from '@egr/wcf/modules/core/view/CameraControl';
import { Subject } from 'rxjs';
import { ArticleElement, MainArticleElement } from '@egr/wcf/modules/cf';
import { EaiwsService } from './eaiws.service';
import { BoundingBox } from '@egr/wcf/modules/core/mdl';

@Injectable({
    providedIn: 'root'
})
export class ViewerService implements OnDestroy {
    showLoading = new Subject<boolean>()
    showSubArtikelMarkerChanged = new Subject<boolean>()
    showSubArtikelMarkerEvent

    eventDoResizeViewer = new Subject<boolean>()
    coreApp: Application | undefined
    subArticleMarkerMaterial: StandardMaterial | undefined
    subArticleMarker: Mesh[] = []
    isSubArticleMarkerVisible = false

    constructor(private eaiwsService: EaiwsService) {
        this.showSubArtikelMarkerEvent = this.showSubArtikelMarkerChanged.subscribe((showMarker) => {
            this.isSubArticleMarkerVisible = showMarker

            this.resetCamera(false)
        })
    }

    ngOnDestroy(): void {
        this.showSubArtikelMarkerEvent.unsubscribe()
    }


    init(coreApp: Application) {
        this.coreApp = coreApp

        const cameraControl = this.coreApp.viewer.view.cameraControl;
        cameraControl.setFixedTarget(new Vector3(0, 0.3, 0));
        cameraControl.dblClickZoomToFitOptions.adjustFixedTarget = false;
        cameraControl.orbitInertia = 0.95;
        this.setMovingAllowed(false)
        this.setZoomAllowed(false)

        const defaultTool: SelectionDefault = new SelectionDefault(this.coreApp);
        defaultTool.deselectionEnabled = false;
        defaultTool.showMainElementSelection = false;
        defaultTool.showElementInteractors = true;
        this.coreApp.tools.defaultTool = defaultTool;
        this.coreApp.tools.startDefaultTool();


        this.coreApp.rendering.addShadowGenerator(new ShadowPlane(this.coreApp));
        this.coreApp.rendering.addShadowGenerator(new ShadowMapping(this.coreApp));


        this.subArticleMarkerMaterial = new StandardMaterial("zMat1", this.coreApp.scene);
        this.subArticleMarkerMaterial.alpha = 1;
        this.subArticleMarkerMaterial.diffuseColor = new Color3(1.0, 181 / 255, 57 / 255);
    }


    /**
    * Should by called after inserting new elements.
    */
    public async resetCamera(resetPosition = true): Promise<boolean> {
        if (!this.coreApp) return false

        if (resetPosition) {
            this.coreApp.viewer.view.cameraControl.setFixedTarget(this.getCenterOfSceneElement());

            const radiusMultiplier = 3; // should be changed to value you want
            const maxZoomRadius: number = this.getRadiusOfSceneElement() * radiusMultiplier; // maxZoomRadius can also be a fixed value, but we take size of element into count
            this.coreApp.viewer.view.cameraControl.setNavigationArea(maxZoomRadius, this.getCenterOfSceneElement());
            this.coreApp.viewer.view.cameraControl.setPosition(CameraControl.DEFAULT_CAMERA_POSITION);
            this.coreApp.viewer.view.cameraControl.zoomToFitElements(null);
        }



        await this.resetMarker()

        this.coreApp.viewer.requestRenderFrame();

        return true
    }

    async resetMarker() {
        if (!this.coreApp) return
        if (!this.subArticleMarkerMaterial) return

        const model = this.eaiwsService.articleManager?.getCurrentElement()
        if (!model) return

        this.subArticleMarker.forEach(mark => {
            mark.dispose()
        })

        if (!this.isSubArticleMarkerVisible) return


        const boxes: ArticleElement[] = []
        if (model.hasSubArticles(false)) {
            const subArticles = model.getSubArticles(true)
            for (let i = 0; i < subArticles.length; i++) {
                const subArticle = subArticles[i]

                const artcielData = await subArticle.getArticleData()
                if (artcielData.seriesId == "SY" && this.eaiwsService.isSematrixPlanningArticle(artcielData.baseArticleNumber) || artcielData.seriesId != "SY") {
                    boxes.push(subArticle)
                }
            }
        } else {
            boxes.push(model)
        }


        boxes.forEach(box => {
            const marker = this.getMarkerFromBoundingBox(box.boundingBox, box.transform)
            if (!marker) return

            this.subArticleMarker.push(marker)
        })
    }

    getMarkerFromBoundingBox(bb: BoundingBox, transform: Matrix) {
        if (!this.coreApp) return false
        if (!this.subArticleMarkerMaterial) return false

        const transformMin = Vector3.TransformCoordinates(bb.min, transform)
        const transformMax = Vector3.TransformCoordinates(bb.max, transform)

        const minX = transformMin.x
        const minZ = transformMin.z
        const maxX = transformMax.x
        const maxZ = transformMax.z
        const y = -0.01


        const tube = CreateTube("zLines", {
            path: [
                new Vector3(minX, y, minZ),
                new Vector3(maxX, y, minZ),
                new Vector3(maxX, y, maxZ),
                new Vector3(minX, y, maxZ),
                new Vector3(minX, y, minZ),
                new Vector3(maxX, y, minZ)
            ],
            radius: 0.005

        }, this.coreApp.scene)
        tube.material = this.subArticleMarkerMaterial

        return tube
    }

    private getCenterOfSceneElement(): Vector3 {
        if (!this.coreApp) return Vector3.Zero();

        if (this.coreApp.model.elements.length > 0 && this.coreApp.model.elements[0].boundingBox.isValid()) {
            return this.coreApp.model.elements[0].boundingBox.getCenter();
        }
        return Vector3.Zero();
    }

    private getRadiusOfSceneElement(): number {
        if (!this.coreApp) return 20

        if (this.coreApp.model.elements.length > 0 && this.coreApp.model.elements[0].boundingBox.isValid()) {
            return this.coreApp.model.elements[0].boundingBox.getRadius();
        }
        return 20; // 20 meter, if nothing is in the scene
    }



    /**
     * If we have multiple articles in the scene (i.e. by loading a .pec), then we need to show the user which one is currently selected by a bounding box.
     */
    public allowMainArticleSelection(value: boolean): boolean {
        if (!this.coreApp) return false

        if (this.coreApp.tools.defaultTool instanceof SelectionDefault) {
            this.coreApp.tools.defaultTool.showMainElementSelection = value;
            this.coreApp.tools.defaultTool.deselectionEnabled = value;
        }

        return true
    }


    setZoomAllowed(value: boolean) {
        if (!this.coreApp) return

        this.coreApp.viewer.view.cameraControl.zoomEnabled = value
    }

    resizeView() {
        this.eventDoResizeViewer.next(true)
    }

    setMovingAllowed(value: boolean) {
        if (!this.coreApp) return

        this.coreApp.viewer.view.cameraControl.panningEnabled = value;
    }
    isMovingAllowed() {
        if (!this.coreApp) return false

        return this.coreApp.viewer.view.cameraControl.panningEnabled;
    }

    zooomBy(amount: number) {
        if (!this.coreApp) return

        this.setZoomAllowed(true)
        this.coreApp.viewer.view.cameraControl.zoomBy(amount)
        this.setZoomAllowed(false)
    }
}
