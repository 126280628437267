import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BasketItem } from '@egr/wcf/modules/eaiws/basket';
import { convertUTF8Text } from 'src/app/helper';
import { DownloadActionOBX, DownloadType } from 'src/app/models/downloadAction';
import { EaiwsOptions } from 'src/app/models/options';
import { BasketFolderService } from 'src/app/services/basket-folder.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { EaiwsService } from 'src/app/services/eaiws.service';
import { PropertyService } from 'src/app/services/property.service';

@Component({
    selector: 'app-basket-ui',
    templateUrl: './basket-ui.component.html',
    styleUrls: ['./basket-ui.component.scss']
})
export class BasketUIComponent implements OnInit, OnDestroy {
    itemsFirstFolder: BasketFolder | undefined
    allItems: BasketItem[] = []
    priceChangedEvent
    obxDownloadAction
    eaiwsFinishedLoadedEvent
    newArticleAddedEvent
    basketReloadedEvent

    @Input() options: EaiwsOptions = {
        canArticleDelete: false,
        canBuy: false,
        showCallToAcion: false
    }
    @Input() set items(value: BasketItem[]) {
        this.allItems = value
    }

    constructor(private eaiwsService: EaiwsService, private propertyService: PropertyService, private basketFolderService: BasketFolderService, private catalogService: CatalogService) {
        this.priceChangedEvent = this.propertyService.priceChanged.subscribe(() => {
            if (!this.itemsFirstFolder) return

            void this.calculatePrice(this.itemsFirstFolder.items)
        })

        this.obxDownloadAction = new DownloadActionOBX("OBX", DownloadType.OBX, this.eaiwsService.eaiwsSession.basket)

        this.eaiwsFinishedLoadedEvent = this.eaiwsService.eventFinishedLoadEAIWS.subscribe(() => {
            this.load()
        })

        this.newArticleAddedEvent = this.catalogService.newArticle.subscribe(() => {
            this.load()
        })
        this.basketReloadedEvent = this.eaiwsService.realodBasketItems.subscribe(() => {
            this.load()
        })
    }

    ngOnInit(): void {

    }
    ngOnDestroy(): void {
        this.priceChangedEvent.unsubscribe()
        this.eaiwsFinishedLoadedEvent.unsubscribe()
        this.newArticleAddedEvent.unsubscribe()
        this.basketReloadedEvent.unsubscribe()
    }

    async load() {
        this.allItems = await this.eaiwsService.eaiwsSession.basket.getAllItems()
        if (this.allItems.length == 0) return

        this.itemsFirstFolder = await this.setupFolder(this.allItems[0].subItemIds)
        void this.calculatePrice(this.itemsFirstFolder.items)
    }

    async calculatePrice(items: BasketFolder[]) {
        if (!this.options.showPrice) return

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const sheets = await this.eaiwsService.eaiwsSession.basket.getPriceCalculationSheets(item.items.map(item => item.current.itemId), "STDB2B_SEDUS_2")

            item.price = sheets.map<number>(sheet => {
                if (!sheet.sheet.netValue) return 0
                return sheet.sheet.netValue.value ? sheet.sheet.netValue.value : 0
            }).reduce((prev, next) => prev + next)
        }

    }

    async setupFolder(subItemIDs: string[] | undefined) {
        const basktFolder: BasketFolder = {
            current: this.allItems[0],
            price: 0,
            items: []
        }

        if (subItemIDs) {

            for (let i = 0; i < subItemIDs.length; i++) {
                const id = subItemIDs[i];
                const basketItem = this.allItems.find(item => item.itemId == id)

                if (!basketItem) continue
                if (!basktFolder) continue
                if (basketItem.itemType == "Text") continue

                if (basketItem.itemType == "Folder") {
                    const folder = await this.setupFolder(basketItem.subItemIds)
                    if (!folder) continue

                    basketItem.label = convertUTF8Text(basketItem.label)
                    folder.current = basketItem
                    basktFolder.items.push(folder)
                } else {
                    basketItem.label = convertUTF8Text(basketItem.label)

                    if (basketItem.label == "") {
                        const props = await this.eaiwsService.eaiwsSession.basket.getItemProperties([basketItem.itemId])
                        basketItem.label = convertUTF8Text(props[0].article!.longText!)
                    }

                    basktFolder.items.push({
                        current: basketItem,
                        price: 0,
                        items: []
                    })
                }
            }
        }

        return basktFolder
    }

    getClassName() {
        return "basket " + this.options.designClass
    }

}


export interface BasketFolder {
    current: BasketItem,
    price: number,
    items: BasketFolder[]
}
