import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ArticleCatalogItem, CatalogItem, LookupOptions } from '@egr/wcf/modules/eaiws/catalog';
import { TranslateService } from '@ngx-translate/core';
import { EaiwsOptions } from 'src/app/models/options';
import { CatalogService } from 'src/app/services/catalog.service';
import { EaiwsService } from 'src/app/services/eaiws.service';

@Component({
    selector: 'app-catalog-ui',
    templateUrl: './catalog-ui.component.html',
    styleUrls: ['./catalog-ui.component.scss']
})
export class CatalogUIComponent implements OnInit, OnDestroy {
    @Input() options: EaiwsOptions = {
        catalogPreSelect: "",
        disabledSeries: []
    }

    private eventFinishedLoadEAIWS
    protected eventCatalogListItemClicked
    public currentPath: CatalogItem[] = []
    public items: CatalogItem[] = []
    public currentClickedSerie: CatalogItem | undefined

    constructor(private eaiwsService: EaiwsService, public catalogService: CatalogService, private translateService: TranslateService) {

        this.eventFinishedLoadEAIWS = this.eaiwsService.eventFinishedLoadEAIWS.subscribe(() => {
            void this.loadItems(true)
        })

        this.eventCatalogListItemClicked = this.catalogService.clickListCatalogItem.subscribe((item) => {
            this.currentPath = [this.currentPath[0]]
            this.onClickIten(item)
        })
    }

    ngOnInit(): void {
        this.translateService.get("label.catalog").subscribe(text => {
            const ofmlName = this.options.manufacturer ? this.options.manufacturer.ofmlName : "klx"
            const startCatalogItem = new CatalogItem()

            startCatalogItem.label = text
            startCatalogItem.name = ofmlName + ":0"

            this.currentPath.push(startCatalogItem)
        })
    }

    ngOnDestroy(): void {
        this.eventFinishedLoadEAIWS.unsubscribe()
        this.eventCatalogListItemClicked.unsubscribe()
    }


    onClickIten(item: CatalogItem) {
        if (item.type == "Folder") {
            this.currentPath.push(item)
            this.loadItems()
        }

        if (item.type == "Article") {
            this.catalogService.newArticle.next(<ArticleCatalogItem>item)
        }
    }

    onClickBreadCrump(item: CatalogItem) {
        const newPath = []

        for (let i = 0; i < this.currentPath.length; i++) {
            const path = this.currentPath[i];

            newPath.push(path)

            if (path == item) {
                break
            }
        }

        this.currentPath = newPath
        this.loadItems()
    }

    async loadItems(firstLoad = false): Promise<boolean> {


        const lookUpOption = new LookupOptions()
        lookUpOption.itemTypes = ["Article", "Folder"]

        this.items = await this.eaiwsService.eaiwsSession.catalog.listCatalogItems(this.getCurrentPath().map(val => val.name), lookUpOption)
        this.items = this.items.filter(item => {
            if (!this.options.disabledSeries) return false

            return this.options.disabledSeries.indexOf(item.catalogNodeKey.substring(0, 3)) == -1
        })

        if (!firstLoad) return true
        return await this.loadPreSelection()
    }

    async loadPreSelection() {
        if (this.options.catalogPreSelect && this.options.catalogPreSelect == "") return true

        const preSelectCatalogItems = this.items.filter(item => {
            if (!this.options.catalogPreSelect) return false

            return item.name.indexOf(this.options.catalogPreSelect) == 0
        })
        if (preSelectCatalogItems.length == 0) return false

        this.currentPath.push(preSelectCatalogItems[0])
        this.currentClickedSerie = preSelectCatalogItems[0]

        return await this.loadItems()
    }

    getCurrentPath() {
        return this.currentPath
    }

    getClassName() {
        return this.options.designClass + " catalog"
    }
}
