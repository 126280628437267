<div id="viewer" #viewer>
    <div class="functions" *ngIf="downloadActions.length > 0">
        <div #downloads class="downloads" [style.width.px]="downloadsWidth">
            <i #downloadIcon class="icon-download-cloud" (click)="onClickDownload()"
                matTooltip="{{'tooltip.download' | translate}}"></i>
            <button *ngFor="let action of downloadActions" (click)="onClickAction(action)">{{action.text}}</button>
        </div>
        <div class="other" [class.isOn]="isDimonsionBoxActive" matTooltip="{{'tooltip.dimension' | translate}}">
            <i class="icon-codeopen" (click)="onClickDimonsion()"></i>
        </div>
        <div class="subarticle" *ngIf="options.canToggleSubarticleMarker" [class.isOn]="isSubarticleActive" matTooltip="{{'tooltip.subarticle' | translate}}">
            <i class="icon-street-view" (click)="onClickSubArticle()"></i>
        </div>
        <div class="move" [class.isOn]="isMovingActive" matTooltip="{{'tooltip.moving' | translate}}">
            <i class="icon-move" (click)="onClickMove()"></i>
        </div>
        <div class="showPrice" *ngIf="options.canPriceDisableEnable" [class.isOn]="isShowPrice"
            matTooltip="{{'tooltip.showPrice' | translate}}">
            <i class="icon-euro" (click)="onClickShowPrice()"></i>
        </div>
        <div class="zoom" *ngIf="options.showZoomIcons" matTooltip="{{'tooltip.showZoom' | translate}}">
            <i *ngIf="!isZoomed" class="icon-zoom-in" (click)="onClickZoom()"></i>
            <i *ngIf="isZoomed" class="icon-zoom-out" (click)="onClickZoom()"></i>
        </div>
    </div>
    <div *ngIf="isLoading" class="loading">
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>