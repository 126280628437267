import { Component, Injector, Input } from '@angular/core';
import { PageW3codeBase } from 'src/app/pages/page-w3code-base';
import { BasketFolderService } from 'src/app/services/basket-folder.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { EaiwsService } from 'src/app/services/eaiws.service';

@Component({
    selector: 'app-messe-ui',
    templateUrl: './messe-ui.component.html',
    styleUrls: ['./messe-ui.component.scss']
})
export class MesseUiComponent extends PageW3codeBase {
    @Input() config?: MesseUIConfig

    constructor(private injector: Injector, private eaiwsService: EaiwsService, private basketFolderService: BasketFolderService, private catalogService: CatalogService) {
        super(injector)
    }


    async onClickPackage(messePackage: MesseUIPackage) {
        if (!this.config) return Promise.reject()

        const obx = await this.eaiwsService.getBlob("assets/messe/" + this.config.messeName + "/" + messePackage.file).catch(() => {
            console.log("Keine OBK gefunden")
        })

        if (!obx) return Promise.reject()

        const data = await this.eaiwsService.eaiwsSession.basket.getAllItems()
        const folderUID = await this.eaiwsService.eaiwsSession.basket.insertFolder(data[0].itemId, null, messePackage.name)

        const url = await this.eaiwsService.eaiwsSession.session.getUploadURL("CutBuffer")
        await this.eaiwsService.eaiwsSession.uploadFileToUrl(url, obx)
        const insertsUIDs = await this.eaiwsService.eaiwsSession.basket.paste(folderUID, null, url)
        await this.eaiwsService.eaiwsSession.deleteFile(url);

        this.eaiwsService.realodBasketItems.next("")

        return
    }
}


export interface MesseUIConfig {
    messeName: string,
    package: MesseUIPackage[]
}

export interface MesseUIPackage {
    name: string,
    file: string,
    image: string
}
