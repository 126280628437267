import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Category, CategoryQuery, CategoryQueryResponse, CategoryWrapper, Manufacturer, MediaTypes, ProductQuery, ProductQueryResponse, UserType } from '../models/pconFacts/pconFactsTypes';
import { ArticleData } from '@egr/wcf/modules/eaiws/basket';
import { LanguageService } from './language.service';

@Injectable({
    providedIn: 'root'
})
export class PconFactsService {
    readonly baseUrl = "https://mamapi.kloeber.com/v3/"


    constructor(private languageService: LanguageService) {


    }

    async loadCategories(article: ArticleData): Promise<CategoryQueryResponse> {
        const query: CategoryQuery = {
            article: {
                manufacturer: article.manufacturerId == "KL" ? Manufacturer.KLOEBER : Manufacturer.SEDUS,
                manufacturerId: article.manufacturerId,
                artNo: article.baseArticleNumber,
                language: this.languageService.getCurrentLanguage().id
            },
            filters: {
                contentTypes: [MediaTypes.all],
                imageMinHeight: 400,
                imageMinWidth: 400
            }
        }


        const pcon = await fetch(this.baseUrl + "categories/query", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(query)
        })

        return await pcon.json()
    }

    async loadAssets(category: CategoryWrapper, article: ArticleData): Promise<ProductQueryResponse> {
        const query: ProductQuery = {
            article: {
                manufacturer: article.manufacturerId == "KL" ? Manufacturer.KLOEBER : Manufacturer.SEDUS,
                manufacturerId: article.manufacturerId,
                artNo: article.baseArticleNumber,
                language: this.languageService.getCurrentLanguage().id
            },
            filters: {
                categories: [category.category],
                contentTypes: [MediaTypes.all],
                imageMinHeight: 400,
                imageMinWidth: 400
            },
            resultRange: {
                count: 99,
                start: 0
            },
            user: {
                type: UserType.public
            }
        }

        const pcon = await fetch(this.baseUrl + "product_information/query", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(query)
        })

        return await pcon.json()
    }
}
